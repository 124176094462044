import React, {Component} from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import LoadingOverlay from "react-loading-overlay";
import {
  MDBInput,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInputGroup,
  MDBListGroup,
  MDBListGroupItem,
  MDBBadge,
} from "mdbreact";
// import httpServices from "../Services/HttpServices";
import "./root.css";
import axios from "axios";
import {Constants} from "../components/Constants";

export default class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: true,
      meetings: [],
      options: [
        {
          text: "",
          value: "",
        },
        {
          text: "kfindemo1",
          API_HOST: "https://kfindemo1.octacomm.io",
          value: "kfindemo1.octacomm.io",
          sharedSecret: "p1AOTOnxuIwrA5jwsLmB1fU76SbVIWMiShufLZgjA",
          checksum: "fcbc4b6c8527a55f8577721f06d883e8680164fb1",
          streaming_host: "http://devlive.octacomm.io",
        },
	{
          text: "test1",
          API_HOST: "https://talk.octacomm.io",
          value: "talk.octacomm.io",
          sharedSecret: "5FbG3r5rh1iX2JQ5PxtSGKc8QtUwO9xE7YFCCz040Q",
          checksum: "fcbc4b6c8527a55f8577721f06d883e8680164fb1",
          streaming_host: "http://devlive.octacomm.io",
        },
        {
          text: "kfindemo1",
          API_HOST: "https://kfindemo1.octacomm.io",
          value: "kfindemo1.octacomm.io",
          sharedSecret: "p1AOTOnxuIwrA5jwsLmB1fU76SbVIWMiShufLZgjA",
          checksum: "fcbc4b6c8527a55f8577721f06d883e8680164fb",
          streaming_host: "http://kfinlive.octacomm.io",
        },

        {
          text: "testing",
          API_HOST: "https://testing.octacomm.io",
          value: "testing.octacomm.io",
          sharedSecret: "ZVNbE7QW4aOyIKMsvbyLjg0URyMntLbNecZphDM4Qw",
          checksum: "fcbc4b6c8527a55f8577721f06d883e8680164fb",
          streaming_host: "http://kfinlive.wetalk.co.in",
        },
        {
        text: "dev3",
        API_HOST: "https://dev3.octacomm.io",
        value: "dev3.octacomm.io",
        sharedSecret: "rvZU9aP6EqsdglYVisi10OH3sd10bY9DO9VIySFg",
        checksum: "fcbc4b6c8527a55f8577721f06d883e8680164fb",
        streaming_host: "http://devlive.octacomm.io",
        },
        {
          text: "kfintechdemo",
          API_HOST: "https://kfintechdemo.octacomm.io",
          value: "kfintechdemo.octacomm.io",
          sharedSecret: "KZotINs6olsJztHrOXqtjfROErgiP5MhXbD9ZG5QN8k",
          checksum: "fcbc4b6c8527a55f8577721f06d883e8680164fb",
          streaming_host: "http://kfinlive.octacomm.io",
          },

      ],
      host: "",
      password: "",
      sharedSecret: "",
      meetingID: "",
      meetingName: "",
      isMeetingOn: false,
      server: {},
      streamStarted: [],
    };

    this.startStreaming = this.startStreaming.bind(this);
    this.stopStreaming = this.stopStreaming.bind(this);
    this.startNodeMediaServer = this.startNodeMediaServer.bind(this);
  }

  update(name, e) {
    this.setState({[name]: e.target.value});
  }

  componentDidMount() {
    this.setState({showSpinner: false});
  }

  componentWillUnmount() {
  }

  startStreaming(e) {
    e.preventDefault();
    this.setState({showSpinner: true});
    this.batchCall();

    setTimeout(() => {
      this.setState({showSpinner: false});
    }, 5000);
  }

  async stopStreaming(e) {
    e.preventDefault();
    this.setState({showSpinner: true});

    // httpServices.stopStreaming(this.state.server.streaming_host);
    const NODE_MEDIA_SERVER_STOP = `${Constants.NODE_MEDIA_STOP}`;
    await axios.get(NODE_MEDIA_SERVER_STOP);

    setTimeout(() => {
      this.setState({showSpinner: false});
    }, 5000);
  }

  async startNodeMediaServer() {
    this.setState({showSpinner: true});

    // httpServices.startNodeMediaServer(this.state.server.streaming_host);
    const NODE_MEDIA_SERVER = `${Constants.NODE_MEDIA_SERVER}`;
    await axios.get(NODE_MEDIA_SERVER);

    setTimeout(() => {
      this.setState({showSpinner: false});
    }, 5000);
  }

  async batchCall() {
    /*  httpServices.batchCall(
      this.state.server.streaming_host,
      this.state.meetingID,
      this.state.host,
      this.state.password,
      this.state.sharedSecret
    ); */
    const BFF_DNS = "http://devlive.octacomm.io"
    const FFMPEG_SERVER = `${BFF_DNS}${Constants.FFMPEG_SERVER}?meetingID=${this.state.meetingID}`;
    const LIVE_RTMP = `${BFF_DNS}${Constants.LIVE_RTMP}?meetingID=${this.state.meetingID}&host=${this.state.host}&password=${this.state.password}&sharedSecret=${this.state.sharedSecret}`;

    await axios.get(FFMPEG_SERVER);
    await axios.get(LIVE_RTMP);

    const dateTime = new Date();
    this.setState({
      isMeetingOn: true,
      streamStarted: [
        {
          server: this.state.host,
          meetingId: this.state.meetingName,
          startAt: dateTime.toLocaleDateString(),
          isRunning: "Yes",
        },
        ...this.state.streamStarted,
      ],
    });
  }

  updateMeeting(name, e) {
    this.update(name, e);
    const meeting_id = e.target.value;
    const meeting = this.state.meetings.filter((server) => {
      return server.meetingID === meeting_id;
    });
    this.setState({
      password: meeting[0].attendeePW,
      meetingName: meeting[0].meetingName,
    });
  }

  async updateHost(name, e) {
    // api/getMeetings
    this.setState({[name]: e.target.value});
    const server = this.state.options.filter((server) => {
      return server.value === e.target.value;
    });
    // console.log(server);
    this.setState({
      server: server[0],
    });
    this.setState({
      sharedSecret: server[0].sharedSecret,
    });

    /* const response = await httpServices
      .getMeetings(server[0].API_HOST, server[0].sharedSecret)
      .catch(() => {
        this.setState({
          meetings: [],
          password: "",
        });
      });
 */
    const URL_NODE_API = `${server[0].API_HOST}/api/getMeetings`;
    const response = await axios.get(URL_NODE_API, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `${server[0].sharedSecret}`,
      },
    });
    console.log(response);
    if (response && response.data.response.messageKey !== "noMeetings") {
      const all_meetings = response.data.response.meetings.meeting;
      if (all_meetings.length > 0) {
        this.setState({
          meetings: all_meetings,
        });
      } else {
        this.setState({
          meetings: [all_meetings],
        });
      }
    } else {
      this.setState({
        meetings: [],
        password: "",
      });
    }
  }

  render() {
    return (
      <MDBContainer>
        <LoadingOverlay
          active={this.state.showSpinner}
          spinner
          text="Loading your content..."
        >
          <MDBRow className="center">
            <MDBCol md="6">
              <p className="h5 text-center mb-4">Streaming Service</p>
              <form onSubmit={this.startStreaming}>
                <MDBInputGroup
                  containerClassName="mb-3"
                  prepend={
                    <select
                      className="browser-default custom-select"
                      value={this.state.host}
                      onChange={(event) => this.updateHost("host", event)}
                    >
                      {this.state.options.map((server, index) => {
                        return (
                          <option key={index} value={server.value}>
                            {server.text}
                          </option>
                        );
                      })}
                    </select>
                  }
                  hint="Shared Secret key"
                  value={this.state.sharedSecret}
                  onChange={(event) => this.update("sharedSecret", event)}
                />
                <div className="grey-text">
                  <select
                    className="browser-default custom-select"
                    value={this.state.meetingID}
                    onChange={(event) => this.updateMeeting("meetingID", event)}
                  >
                    <option key="" value=""></option>
                    {this.state.meetings.map((server, index) => {
                      return (
                        <option key={index} value={server.meetingID}>
                          {Object.keys(server.meetingName).length > 0
                            ? server.meetingName
                            : server.meetingID}
                        </option>
                      );
                    })}
                  </select>
                  <MDBInput
                    label="Meeting Attendee Password"
                    icon="lock"
                    group
                    type="text"
                    validate
                    value={this.state.password}
                    onChange={(event) => this.update("password", event)}
                  />
                </div>
                <div className="text-center">
                  {/* <MDBBtn type="submit">Start Streaming</MDBBtn> */}
                  <button
                    className="btn-default btn Ripple-parent"
                    type="submit"
                  >
                    Start Streamer
                    <div
                      data-test="waves"
                      className="Ripple is-reppling rp"
                    ></div>
                  </button>
                  {/* <MDBBtn color="danger">Stop Streaming</MDBBtn> */}
                </div>
              </form>
              <div className="text-center">
                {/* <button
                  className="btn-default btn Ripple-parent"
                  onClick={this.startNodeMediaServer}
                >
                  Start Node Media Server
                  <div
                    data-test="waves"
                    className="Ripple is-reppling rp"
                  ></div>
                </button> */}
                {/* <button
                  className="btn-danger btn Ripple-parent"
                  onClick={this.stopStreaming}
                >
                  Stop Node Media Server
                  <div
                    data-test="waves"
                    className="Ripple is-reppling rp"
                  ></div>
                </button> */}
              </div>
            </MDBCol>

            <MDBCol md="6">
              <MDBListGroup style={{width: "22rem"}}>
                {this.state.streamStarted.length > 0 &&
                  this.state.streamStarted.map((details, index) => {
                    return (
                      <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                        {details.server}
                        <MDBBadge color="green" pill>
                          {details.meetingId}
                        </MDBBadge>
                        <MDBBadge color="primary" pill>
                          {details.startAt}
                        </MDBBadge>
                      </MDBListGroupItem>
                    );
                  })}
              </MDBListGroup>
            </MDBCol>
          </MDBRow>
        </LoadingOverlay>
      </MDBContainer>
    );
  }
}
