import React, {useEffect, useRef, useState} from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./videoPlayer.css";
import Chat from "./Chat";
import {MDBIcon} from "mdbreact";
import feedbackIcon from "../img/icon16.png";
import banner_kfin from "../img/banner_kfin.jpg";
import streamer_info from "../img/streamer-info.jpeg";
import octacommIcon from "../img/octalogo.png";
import { isMobile } from 'react-device-detect';

const VideoPlayer = (props) => {
  const [stream, setStream] = useState(false);
  const [videoJsOptions, setVideoJsOptions] = useState(null)
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (!userId) {
      window.location.href = "/";
    }
    const {meetingId} = props.match.params;
    const host = "https://devlive1.octacomm.io";
    setStream(true)
    console.log({
      autoplay: true,
      controls: true,
      preload: "auto",
      sources: [
        {
          src: `${host}/live/${meetingId}/index.m3u8`,
          type: "application/x-mpegURL",
        },
      ],
      fluid: true,
    })
    setVideoJsOptions({
      autoplay: true,
      controls: true,
      preload: "auto",
      sources: [
        {
          src: `${host}/live/${meetingId}/index.m3u8`,
          type: "application/x-mpegURL",
        },
      ],
      fluid: true,
    })
  }, [])
  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current && videoRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      playerRef.current = videojs(videoElement, videoJsOptions, () => {
        videojs.log('player is ready');
        setStream(true);
      });

      playerRef.current.on('error', () => {
        setStream(false);
      })

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      if (player && player.autoplay) player.autoplay(videoJsOptions.autoplay);
      if (player && player.src) player.src(videoJsOptions.sources);
    }
  }, [videoJsOptions, videoRef]);
  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const openVoting = () => {
    // TODO
    const userId = localStorage.getItem("userId");
    const URL = `https://emeetings.kfintech.com/room/instapoll.aspx?folio=${userId}`;
    window.open(
      URL,
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  }
  const askQuestion = () => {
    const userId = localStorage.getItem("userId");
    const URL = `https://emeetings.kfintech.com/room/askaquestion.aspx?folio=${userId}`;
    window.open(
      URL,
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  }
  const feedBack = () => {
    const userId = localStorage.getItem("userId");
    const URL = `https://emeetings.kfintech.com/feedback/feedback.aspx?q=${userId}`;
    window.open(
      URL,
      "_blank",
      "location=yes,height=1000,width=1000,scrollbars=yes,status=yes"
    );
  }


  return (
    <>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <img
          className="logoImg"
          src={octacommIcon}
        />

    { !isMobile &&
        (<div>
          <button
            className="btn-primary btn Ripple-parent left btn-vote"
            onClick={openVoting}
          >
            <MDBIcon far icon="thumbs-up"/> Vote
            <div data-test="waves" className="Ripple is-reppling rp"></div>
          </button>

          <button
            className="btn-primary btn Ripple-parent left btn-new"
            onClick={askQuestion}
          >
            <MDBIcon far icon="question-circle"/> Ask a Question
            <div data-test="waves" className="Ripple is-reppling rp"></div>
          </button>
          <button
            className="btn-primary btn Ripple-parent left btn-vote"
            onClick={feedBack}
          >
            <img src={feedbackIcon}/> Feedback
            <div data-test="waves" className="Ripple is-reppling rp"></div>
          </button>
          </div>
      )}
      </div>
      <div className="main">
        <div className="col-md-12 mx-auto">
          {stream ? (
            <div data-vjs-player ref={videoRef}>

            </div>
          ) : (
            <img style={{width: '100%', height: '100%', marginTop: '10px'}} src={streamer_info} />
          )}
          {/* <div id="disqus_thread"></div> */}
          { isMobile &&
            (<div>
              <button
                className="btn-primary btn Ripple-parent left btn-vote"
                onClick={openVoting}
              >
                <MDBIcon far icon="thumbs-up"/> Vote
                <div data-test="waves" className="Ripple is-reppling rp"></div>
              </button>

              <button
                className="btn-primary btn Ripple-parent left btn-new"
                onClick={askQuestion}
              >
                <MDBIcon far icon="question-circle"/> Ask a Question
                <div data-test="waves" className="Ripple is-reppling rp"></div>
              </button>
              <button
                className="btn-primary btn Ripple-parent left btn-vote"
                onClick={feedBack}
              >
                <img src={feedbackIcon}/> Feedback
                <div data-test="waves" className="Ripple is-reppling rp"></div>
              </button>
              </div>
          )}
        </div>
      </div>
      {/* <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 mx-auto mt-5 ">
            <Chat meetingId={props.match.params.meetingId} />
          </div>
        </div> */}
    </>
  );

}


export default VideoPlayer;
